import React from 'react'

const Reactloading = () => {

  return (
    
        <div className='loading'>
            Bitte warten
        </div>
    
  )
}

export default Reactloading