import React from 'react';
import './CardExampleCard.css'
import moment from 'moment'

const CardExampleCard = ({weatherData}) => {

    return (
        <div className='card_container'>
            <div className='card_header'>
                <h2>Ortsname: {weatherData.name}</h2>
                <h3>Tag: {moment().format('dddd')}</h3>
                <h4>Datum: {moment().format('LL')}</h4>
            </div>
            <div className="flex">
                <p className='important'>Temperatur: {weatherData.main.temp} &deg;C</p>
                <p className='important'>Beschreibung: {weatherData.weather[0].description}</p>
            </div>
            <div className="flex">
                <p className='sunrise-sunset'>Sonnenaufgang: {new Date(weatherData.sys.sunrise * 1000).toLocaleTimeString()} Uhr</p>
                <p className='sunrise-sunset'>Sonnenuntergang: {new Date(weatherData.sys.sunset * 1000).toLocaleTimeString()} Uhr</p>
            </div>
            
            <div className="flex">
                <p className="humidity">Humidity: {weatherData.main.humidity} %</p>
            </div>
            
            
        </div>
    )
  }

export default CardExampleCard;