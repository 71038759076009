import React, { useEffect, useState } from 'react'
import Weather from './components/Weather';
import ReactLoading from './components/Reactloading';

const App = () => {

    const [lat, setLat] = useState([]);
  const [long, setLong] = useState([]);
  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      navigator.geolocation.getCurrentPosition(function(position) {
        setLat(position.coords.latitude);
        setLong(position.coords.longitude);
      });

      await fetch(`${process.env.REACT_APP_API_URL}/weather/?lat=${lat}&lon=${long}&units=metric&APPID=${process.env.REACT_APP_API_KEY}`)
      .then(res => res.json())
      .then(result => {
        setData(result)
      });
    }

   console.log(data);
   console.log(lat)
   setTimeout(fetchData,
    5000
)
  }, [lat,long, data])
  
  return (
    <section id='app'>
        {(typeof data.main != 'undefined') ? (
          <Weather weatherData={data} />
        ) : (
          <div>
            <ReactLoading type='cubes'/>
          </div>
        )}
        
    </section>
  )
}

export default App