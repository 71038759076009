import React from 'react'
import CardExampleCard from './CardExampleCard'

const Weather = ({weatherData}) => {

  return (
    <section id='weather'>
        <CardExampleCard weatherData={weatherData}/>
    </section>
  )
}

export default Weather